.pricesMain {
  width: 80%;
  margin: 0px auto;
}

@media (max-width: 1300px) {
  .pricesMain {
    width: 90%;
  }
}

@media (max-width: 1200px) {
  .pricesMain {
    width: 95%;
  }
}
