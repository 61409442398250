@keyframes apearRight {
  0% {
    translate: 10vw 0px;
  }
  100% {
    translate: 0vw 0px;
  }
}

@keyframes appearance-left {
  0% {
    translate: -100% 0px;
  }
  100% {
    translate: 0px 0px;
  }
}
