.card-blok {
  margin-top: 40px;
  border-bottom: 1px solid red;
  padding-bottom: 15px;
}

.card-blok > h2 {
  font-size: 1.7rem;
  margin-bottom: 13px;
}

.card {
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card-img {
  width: 35%;
}

.card-img img {
  width: 100%;
}

.card-video-text {
  display: flex;
  flex-direction: column;
  width: 63%;
}

.card-video-text iframe {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.text-in-card {
  padding-top: 10px;
}

.text-in-card h3 {
  padding-bottom: 7px;
  font-size: 1.5rem;
}

.text-in-card ul {
  list-style: none;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text-in-card ul li {
  font-size: 1.3rem;
}

.text-in-card > p {
  padding-top: 10px;
  font-size: 1.4rem;
}

.text-in-card > hr {
  width: 10%;
  margin-bottom: 11px;
  height: 8px;
  background-color: red;
  border-width: 0;
}

@media (max-width: 1200px) {
  .text-in-card ul li {
    font-size: 1.1rem;
  }

  .text-in-card > p {
    font-size: 1.2rem;
  }
}

@media (max-width: 900px) {
  .text-in-card {
    width: 91%;
    margin: 0px auto;
  }

  .text-in-card h3 {
    margin-top: 12px;
    margin-bottom: 7px;
  }
  .text-in-card ul {
    gap: 13px;
  }

  .text-in-card ul li {
    font-size: 1.3rem;
  }

  .text-in-card > p {
    font-size: 1.4rem;
  }
}

@media (max-width: 500px) {
  .text-in-card > hr {
    width: 17%;
    height: 7px;
  }
  .text-in-card h3 {
    font-size: 1.4rem;
    margin-top: 12px;
    margin-bottom: 7px;
  }

  .text-in-card ul li {
    font-size: 1.1rem;
  }

  .text-in-card > p {
    font-size: 1.2rem;
  }
}
