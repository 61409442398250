@import url("../Animations.css");

.blok-link-toPrices {
  margin: 0px auto;
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.blok-link-toPrices > button {
  border: 1px solid red;
  border-radius: 5px;
  background-color: white;
  color: red;
  font-size: 1.4rem;
}

.blok-link-toPrices > button:hover {
  cursor: pointer;
}

.weight-text {
  font-weight: bold;
}

.toRight {
  margin-left: auto;
}
.toLeft {
  margin-right: auto;
}

.toRight-content-on-img {
  left: 100%;
  transform: translate(-100%, -50%);
  /* animation-name: apearRight;
  animation-duration: 1.5s; */
}
.toLeft-content-on-img {
  transform: translate(0%, -50%);
}

.img-under-content {
  width: 85%;
}

.img-under-content img {
  border-radius: 1%;
  width: 100%;
}

.content-on-img {
  overflow: hidden;
  position: absolute;
  background-color: white;
  /* background-color: pink; */
  width: 54%;
  /* height: 64%; */
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 3vw;

  box-sizing: border-box;
  /* border-radius: 0%;
  border: 1px solid red; */
}

.content-on-img h2 {
  text-align: left;
  font-size: 1.5vw;
}

.content-on-img ul {
  /* list-style: "\2713";  */
  padding-left: 2.5vw;
  margin-top: 1vw;
  display: flex;
  flex-direction: column;
  gap: 0.9vw;
}

.content-on-img ul li {
  font-size: 1.1vw;
  text-align: justify;
}

.content-on-img hr {
  width: 12%;
  margin-bottom: 1vw;
  height: 0.7vw;
  background-color: red;
  border-width: 0;
}

.content-on-imgSection {
  /* background-color: aqua; */
  position: relative;
  width: 80%;
  margin: 0px auto;
  margin-top: 5vw;
}

.bloks-section {
  width: 80%;
  margin: 0px auto;
  margin-top: 5vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.text-img-blok {
  width: 48%;
  padding-top: 0.9vw;
  /* border: 1px solid red; */
  box-sizing: border-box;
}
.text-img-blok img {
  border-radius: 1%;
  width: 100%;
}

.textOn-blokImgText {
  margin: 0 auto;
  width: 100%;
}

.text-img-blok hr {
  width: 10%;
  margin-top: 1.5vw;
  margin-bottom: 1vw;
  height: 0.5vw;
  background-color: red;
  border-width: 0;
}

.text-img-blok h2 {
  text-align: left;
  font-size: 1.5vw;
}

.text-img-blok ul {
  /* list-style: "\2713"; */
  padding-left: 1vw;
  margin-top: 1vw;
  display: flex;
  flex-direction: column;
  gap: 0.9vw;
}

.text-img-blok ul li {
  font-size: 1.1vw;
  /* text-align: justify; */
}

/* .large-contetnt-on-image {
} */

@media (max-width: 1450px) {
  .content-on-img h2 {
    font-size: 1.75vw;
  }

  .content-on-img ul {
    padding-left: 2.5vw;
    margin-top: 1vw;
    gap: 0.7vw;
  }

  .content-on-img ul li {
    font-size: 1.2vw;
  }

  .content-on-imgSection {
    width: 85%;
  }

  .bloks-section {
    width: 85%;
  }

  .text-img-blok h2 {
    font-size: 1.8vw;
  }

  .text-img-blok ul li {
    font-size: 1.3vw;
  }
}

@media (max-width: 1300px) {
  .textOn-blokImgText {
    width: 90%;
  }

  .content-on-img h2 {
    font-size: 2vw;
  }

  .content-on-img ul {
    padding-left: 2.5vw;
  }

  .content-on-img ul li {
    font-size: 1.4vw;
  }

  .content-on-imgSection {
    width: 95%;
  }

  .bloks-section {
    width: 95%;
  }

  .text-img-blok h2 {
    font-size: 2.2vw;
  }

  .text-img-blok ul li {
    font-size: 1.43vw;
  }
}

@media (max-width: 1100px) {
  .content-on-img h2 {
    font-size: 2.3vw;
  }

  .content-on-img ul {
    padding-left: 2vw;
    margin-top: 0.9vw;
    gap: 0.6vw;
  }

  .content-on-img ul li {
    font-size: 1.6vw;
  }

  .content-on-imgSection {
    width: 99%;
  }

  .bloks-section {
    width: 99%;
  }

  .text-img-blok h2 {
    font-size: 2.7vw;
  }

  .text-img-blok ul {
    padding-left: 1vw;
    margin-top: 0.9vw;
    gap: 0.9vw;
  }

  .text-img-blok ul li {
    font-size: 1.8vw;
  }
}

@media (max-width: 950px) {
  .img-under-content {
    width: 100%;
  }

  .content-on-img h2 {
    font-size: 3.2vw;
  }

  .content-on-img {
    width: 75%;
  }

  .content-on-img ul {
    padding-left: 2vw;
    margin-top: 1.4vw;
    gap: 0.6vw;
  }

  .content-on-img ul li {
    font-size: 2vw;
  }

  .text-img-blok {
    width: 49%;
  }
  .text-img-blok h2 {
    font-size: 3.2vw;
  }

  .text-img-blok ul {
    padding-left: 2vw;
    margin-top: 0.9vw;
    gap: 0.9vw;
  }

  .text-img-blok ul li {
    font-size: 2.5vw;
  }
}

@media (max-width: 800px) {
  .content-on-img {
    width: 90%;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -60%);

    padding: 4vw;
  }

  .content-on-img h2 {
    font-size: 3.8vw;
  }

  .content-on-img ul li {
    font-size: 1.99vw;
  }

  .content-on-imgSection {
    margin-bottom: 23%;
  }

  .bloks-section {
    width: 100%;
    margin-top: 25vw;
  }

  .text-img-blok h2 {
    font-size: 3.2vw;
  }

  .text-img-blok ul {
    padding-left: 2.3vw;
    margin-top: 1vw;
    gap: 1.1vw;
  }

  .text-img-blok ul li {
    font-size: 2.2vw;
  }
}

@media (max-width: 600px) {
  .text-img-blok {
    width: 100%;
    padding-bottom: 40px;
  }
  .textOn-blokImgText {
    width: 90%;
  }
  .text-img-blok hr {
    height: 1.1vw;
    width: 18%;
  }
  .content-on-img {
    height: none;
    position: static;
    margin: 0px auto;
    width: 95%;
    transform: translate(0%, 0%);

    padding: 4vw;
  }
  .content-on-img hr {
    height: 1.3vw;
    width: 16%;
  }
  .content-on-img h2 {
    font-size: 5vw;
  }

  .content-on-img ul {
    padding-left: 2vw;
    margin-top: 0.9vw;
    gap: 0.8rem;
  }

  .content-on-img ul li {
    font-size: 3.5vw;
    text-align: left;
  }

  .content-on-imgSection {
    margin-bottom: 8vw;
  }

  .bloks-section {
    margin-top: 30px;
  }

  .text-img-blok h2 {
    font-size: 5.3vw;
  }

  .text-img-blok ul {
    padding-left: 2.8vw;
    margin-top: 4vw;
    gap: 1.1vw;
  }

  .text-img-blok ul li {
    font-size: 3.4vw;
  }
}

@media (max-width: 400px) {
  .textOn-blokImgText {
    width: 87%;
    margin-top: 4vw;
  }
  .content-on-img h2 {
    font-size: 5.8vw;
  }

  .content-on-img ul li {
    font-size: 4.5vw;
  }

  .text-img-blok ul li {
    font-size: 4.5vw;
  }

  .text-img-blok h2 {
    font-size: 5.8vw;
  }
}
