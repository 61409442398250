.footer {
  margin: 0px auto;
  width: 80%;
  margin-top: 50px;
}

.footer h2 {
  font-size: 1.8rem;
}

.footer-info {
  width: 70%;
  margin: 0px auto;
  margin-top: 30px;
}

.footer-info p {
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
}

.footer-contacts {
  width: 70%;
  margin: 0px auto;
  margin-top: 50px;
  display: flex;
}

.footer-contacts div {
  width: 50%;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.footer-contacts p {
  font-size: 1.3rem;
  text-align: center;
}

@media (max-width: 1100px) {
  .footer {
    width: 90%;
  }
  .footer-info {
    width: 90%;
  }
  .footer-contacts {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .footer-info {
    width: 100%;
  }
  .footer-contacts {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .footer h2 {
    font-size: 1.6rem;
  }
  .footer-info p {
    font-size: 1.1rem;
  }
  .footer-contacts div p {
    font-size: 1.1rem;
  }
}

@media (max-width: 500px) {
  .footer h2 {
    font-size: 6vw;
  }
  .footer-info p {
    font-size: 4vw;
  }
  .footer-contacts div p {
    font-size: 3.6vw;
  }
  .footer-contacts div {
    gap: 10px;
  }
}
