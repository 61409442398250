.Header {
  margin-top: 0px;
  position: relative;
}

.Header img {
  width: 100%;
}

.elementOnImage {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0px, -50%);
}

.elementOnImage h1 {
  color: black;
}

.elementOnImage h1 {
  font-weight: bold;
  text-shadow: 2px 2px 4px white;
}

@media (max-width: 400px) {
  .elementOnImage h1 {
    font-size: 1.6rem;
  }
}
