@font-face {
  font-family: Robot-Slab-local;
  src: url("./fonts/RobotoSlab-VariableFont_wght.ttf");
}

* {
  margin: 0px;
}
body {
  background-color: white;
  font-family: Robot-Slab-local, sans-serif, Times;
  /* "Roboto Slab" */
}

.App {
  /* background-color: green; */
  width: 100%;
  margin: 0px auto;
}

h1,
h2,
h3 {
  text-align: center;
}

.increased-line-height {
  line-height: 1.8;
}
