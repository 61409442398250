@import url(./Animations.css);

.navbar-height {
  height: 5rem;
}

#main-navbar {
  background-color: white;
  width: 100%;
  position: fixed;
  z-index: 2;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
}

.NavLink {
  text-decoration: none;
  color: black;
}

.navbar {
  width: 75%;
  height: 100%;
  /* background-color: aqua; */
  display: flex;
  margin: 0px auto;
  box-sizing: border-box;
  align-items: center;
  padding: 10px 10px;
}

.navbar-items {
  margin-left: auto;
  list-style: none;
  display: flex;
  /* gap: 40px; */
}

.navbar-items li {
  font-size: 1.2rem;
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: 0px;
  padding: 10px 20px;
  box-sizing: border-box;
}

.Logo {
  color: red;
  font-size: 2rem;
}

.burger {
  margin-left: auto;
  display: none;
  flex-direction: column;
  cursor: pointer;
}
.burger-active {
  flex-direction: row;
}

.burger-lines {
  width: 28px;
  height: 2px;
  background-color: #333;
  margin: 3px;
  transition: all 0.3s ease;
}

.burger-lines-active {
  width: 2px;
  height: 28px;
}

@media (hover: hover) {
  .navbar-items li:hover {
    cursor: pointer;
    transition: border;
    border-bottom: 1px solid rgba(255, 0, 0, 1);
    transition-duration: 1s;
    transition-timing-function: ease;
  }
  .Logo:hover {
    cursor: pointer;
    transition: transform;
    transition-duration: 1s;
    transition-timing-function: ease-out;
    transform: scale(1.2);
  }
}

@media (hover: none) {
  .navbar-items li:active {
    transition: border;
    border-bottom: 1px solid rgba(255, 0, 0, 1);
  }
  .Logo:active {
    cursor: pointer;
    transition: transform;
    transition-duration: 0s;
    transition-timing-function: ease-out;
    transform: scale(1.2);
  }
}

@media (max-width: 900px) {
  .navbar {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .navbar {
    width: 98%;
  }
  .navbar-items li {
    padding: 10px 10px;
  }
  .navbar-height {
    height: 4rem;
  }
}

@media (max-width: 600px) {
  .Logo {
    font-size: 1.6rem;
  }
  .navbar-items {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 100%;
    transform: translate(-100%, 0px);
  }

  .navbar-items li {
    padding: 10px 20px;
    background-color: white;
  }

  .burger {
    display: flex;
  }
  .navbar-items-active {
    display: flex;
    animation-name: appearance-left;
    animation-duration: 0.5s;
  }
}
